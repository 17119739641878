import React, {useContext} from 'react';
import './Vision.scss';
import { Context } from '../Store';
import line1 from "../../imgs/line1.png";
import logo2 from "../../imgs/logo2.png";
const Vision = () => {
    let{menu,visionSection} = useContext(Context);
  return (
   <>
   <section id='vision' ref={visionSection}>
    <div className='container'>
    <div className='title'>
        <h2>رؤيتنـــــــــــا</h2>
        <img src={line1} width="37%" alt="" />
    </div>
    <div className='content'>
        <div className='item0'>
            <img src={logo2} alt="" />
        </div>
        <p>{menu.vision_ar}</p>
        <div className='item1'>
            <img src={logo2} alt="" />
        </div>
    </div>
    </div>
   </section>
   </>
  )
};

export default Vision;