import React, { useContext } from "react";
import "./Slider.scss";
import { Context } from "../Store";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper";
const Slider = () => {
  let { sliders } = useContext(Context);

  function checkImgURL(url) {
    if (typeof url !== "string") return false;
    return url.match(/(https?:\/\/.*\.(?:jpg|jpeg|gif|png|tiff|bmp))/i) != null;
  }
  function checkVideoURL(url) {
    if (typeof url !== "string") return false;
    return url.match(/(https?:\/\/.*\.(?:webm|mkv|flv|mp4))/i) != null;
  }

  const renderMedia = (url) => {
    if (checkImgURL(url)) return <img src={url} alt="" />;
    else if (checkVideoURL(url))
      return (
        <video className="slide-video" src={url} autoPlay muted loop></video>
      );
  };

  return (
    <>
      <section id="slider">
        <div className="container">
          <Swiper
            className="imgSwiper"
            dir="rtl"
            slidesPerView={1}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Autoplay, Pagination]}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //     delay: 3000,
            //     disableOnInteraction: false
            // }}
          >
            {sliders.length > 0 ? (
              ""
            ) : (
              <div className="loadingLayer">
                <i className={`fa fa-spinner fa-5x fa-spin loading`}></i>
              </div>
            )}
            {sliders.map((slider) => (
              <SwiperSlide key={slider.id}>
                {renderMedia(slider.image)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Slider;
