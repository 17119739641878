import React, {useContext} from 'react';
import './Work.scss';
import { Context } from '../Store';
import line2 from "../../imgs/line2.png";
// import bgWork from "../../imgs/bgWork.png";

const Work = () => {
    let{works} = useContext(Context);
  return (
    <>
    <section id='work'>
    {works.length > 0 ? "" :<div className="loadingLayer"><i className={`fa fa-spinner fa-5x fa-spin loading`}></i></div>}
        <div className='container'>
            <div className='title'>
                <h2>أعمال الشركــــــــــــة</h2>
                <img src={line2} width="37%" alt="" />
                
            </div>
            <div className='content'>
                {works.map((work)=>(
                    <div className='item' key={work.id}>
                        <div className='summary'>
                            <span>{work.number}+</span>
                            <p className='titleWork'>{work.name_ar}</p>
                        </div>
                        
                    {work.areas.map((item)=>(
                        <p key={item.id}><i className="fa-solid fa-location-dot"></i>{item.title}</p>
                    ))}
                    </div>
                ))}
            </div>
        </div>
    </section>
    </>
  )
};

export default Work;