import React, {useContext} from 'react';
import './Partner.scss';
import { Context } from '../Store';
import line0 from "../../imgs/line0.png";

const Partner = () => {
    let{partners,partnersSection} = useContext(Context);
  return (
    <>
    <section id='partner' ref={partnersSection}>
    {partners.length > 0 ? "" :<div className="loadingLayer"><i className={`fa fa-spinner fa-5x fa-spin loading`}></i></div>}
        <div className='container'>
            <div className='title'>
                <h2>شركاؤنــــــــــا</h2>
                <img src={line0} width="37%" alt="" />
            </div>
            <div className='content'>
              {partners.map((partner)=>(
                <div className='item' key={partner.id}>
                  <img src={partner.image} alt="" />
                </div>
              ))}
            </div>
        </div>
    </section>


    </>
   
  )
};

export default Partner;