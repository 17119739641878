import axios from "axios";
import React, { useState, createContext, useRef, useEffect } from "react";

export let Context = createContext(0);
const ContextProvider = (props) => {
  const { dir, setDir } = props;
  const [isClicked, setIsClicked] = useState(false);
  const homeSection = useRef(null);
  const servicesSection = useRef(null);
  const partnersSection = useRef(null);
  const messageSection = useRef(null);
  const visionSection = useRef(null);
  const baseURL = "https://muhra.almaharh.com/api";

  ///////////////////////////////
  const scrollToSection = async (elementRef) => {
    if (elementRef.current && window.innerWidth > 993) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 180, /// 180 = navbar height
        behavior: "smooth",
      });
      setIsClicked(false);
    }
    if (elementRef.current && window.innerWidth <= 993) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 90, /// 90 = navbar height
        behavior: "smooth",
      });
      setIsClicked(false);
    }
    // else{
    //   await new Promise((resolve)=>setTimeout(resolve,250));
    //   window.scrollTo({
    //     top:elementRef.current.offsetTop-96, /// 96 = navbar height
    //     behavior:"smooth",
    //   })
    // }
  };

  ////////////////////////////////
  const handleClick = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  //////////////////////////////////
  // const handelDirection  = () => {
  //   if(dir==="ltr" ){
  //     setDir("rtl");
  //     localStorage.setItem("dir","rtl");
  //     document.body.style.direction = 'rtl';
  //   }else{
  //     setDir("ltr");
  //     localStorage.setItem("dir","ltr");
  //     document.body.style.direction = 'ltr';
  //  }
  // };
  //////////////get APIs//////////////////////
  let [sliders, setSliders] = useState([]);
  let [menu, setMenu] = useState("");
  let [info, setInfo] = useState("");
  let [services, setServices] = useState([]);
  let [works, setWorks] = useState([]);
  let [partners, setPartners] = useState([]);

  const getSliders = async (callback) => {
    let { data } = await axios.get(`${baseURL}/slider`);
    callback(data.data);
  };
  /////////////////
  const getMenu = async (callback) => {
    let { data } = await axios.get(`${baseURL}/get-menu`);
    callback(data.data);
  };
  //////////////////////////////////
  const getInfo = async (callback) => {
    let { data } = await axios.get(`${baseURL}/information`);
    callback(data.data);
  };
  /////////////////
  const getServices = async (callback) => {
    let { data } = await axios.get(`${baseURL}/services`);
    callback(data.data);
  };
  ////////////////////////////
  const getWorks = async (callback) => {
    let { data } = await axios.get(`${baseURL}/works`);
    callback(data.data);
  };
  ////////////////////////
  const getPartners = async (callback) => {
    let { data } = await axios.get(`${baseURL}/clients`);
    callback(data.data);
  };
  ////////////////////////
  useEffect(() => {
    getSliders(setSliders);
    getMenu(setMenu);
    getInfo(setInfo);
    getServices(setServices);
    getWorks(setWorks);
    getPartners(setPartners);
  }, []);

  /////////////////////////////////

  return (
    <Context.Provider
      value={{
        dir,
        isClicked,
        handleClick,
        scrollToSection,
        homeSection,
        servicesSection,
        partnersSection,
        messageSection,
        visionSection,
        sliders,
        menu,
        info,
        services,
        works,
        partners,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default ContextProvider;
