import React, {useContext} from 'react';
import './Message.scss';
import { Context } from '../Store';
import line0 from "../../imgs/line0.png";
import kite from "../../imgs/kite.png";
const Message = () => {
    const{menu,messageSection} = useContext(Context);
  return (
    <>
    <section id='message' ref={messageSection}>
        <img src={kite} className="bgKite" alt="" />
        <div className='container'>
         <div className='title'>
            <h2>رسالتنــــــــا</h2>
            <img src={line0} width="37%" alt="" />
         </div>
         <p>{menu.message_ar}</p>
        </div>
    </section>
    
    </>
  )
};

export default Message;