import React, {useContext} from 'react';
import './Footer.scss';
import { Context } from '../Store';
import logo3 from "../../imgs/logo3.png";
const Footer = () => {
    let{info} = useContext(Context);
  return (
    <>
    <section id='footer'>
        <div className='container'>
            <img className='footerLogo' src={logo3} alt="" />
            <div className='content'>
                <p> <i className="fa-solid fa-phone"></i>{info.phone}</p>
                <a href={info.site===null?"www.almaharh.com":info.site}>{info.site===null?"www.almaharh.com":info.site}<i className="fa-solid fa-globe"></i></a>
            </div>
        </div>
    </section>

    </>
  )
}

export default Footer;