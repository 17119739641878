import React from 'react';
import ContextProvider from './components/Store';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import Slider from './components/Slider/Slider';
import AboutUs from './components/AboutUs/AboutUs';
import Services from './components/Services/Services';
import Vision from './components/Vision/Vision';
import Message from './components/Message/Message';
import Work from './components/Work/Work';
import Partner from './components/Partner/Partner';
import Footer from './components/Footer/Footer';
function App() {
  // const [dir, setDir] = useState('rtl');
  // useEffect(() => {
   
  //   if(localStorage.getItem("dir")){
  //     setDir(localStorage.getItem("dir"));
  //     document.body.style.direction = dir;
  //   }else{
  //     localStorage.setItem("dir","rtl");
  //   }
  // }, [dir]);
  //////////////////////////////////
  return (
    <>
    <ContextProvider
          // dir={dir} setDir={setDir}
          >
          <Navbar/> 
          <div className='navDistance'></div>
          <Slider/> 
          <AboutUs/> 
          <Services/> 
          <Vision/> 
          <Message/> 
          <Work/> 
          <Partner/> 
          <Footer/> 
      </ContextProvider>
    </>
    
  );
}

export default App;
