import React, {useContext} from 'react';
import './AboutUs.scss';
import { Context } from '../Store';
import logo1 from "../../imgs/logo1.png";
const AboutUs = () => {
    let{menu} = useContext(Context);
  return (
    <>
    <section id="aboutUs">
        <div className='container'>
            <img src={logo1} alt="" />
            <p>{menu.about_us_ar}</p>
        </div>
    </section>
    </>
  )
};

export default AboutUs;