import React, {useContext} from 'react';
import './Services.scss';
import { Context } from '../Store';
import line0 from "../../imgs/line0.png";
const Services = () => {
    let{services,servicesSection} = useContext(Context);
  return (
    <>
    <section id="services" ref={servicesSection}>
    {services.length > 0 ? "" :<div className="loadingLayer"><i className={`fa fa-spinner fa-5x fa-spin loading`}></i></div>}
        <div className='container'>
            <div className='title'>
                <h2>خدماتنــــــــا</h2>
                <img src={line0} width="37%" alt="" />
            </div>
            <div className='content'>
                {services.map((service)=>(
                    <div className='service' key={service.id}>
                    <div className='imgBorder'>
                        <img src={service.icon} alt="" />
                    </div>
                    <p>{service.title}</p>
                </div>
                ))}
            </div>
        </div>
    </section>
    
    </>
  )
};

export default Services;