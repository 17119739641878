import React, {useContext} from 'react';
import './Navbar.scss';
import navLine from "../../imgs/navLine.svg";
import { Context } from '../Store';
import logo0 from "../../imgs/logo0.png";



const Navbar = () => {
  let{handleClick,isClicked,scrollToSection,homeSection,servicesSection,partnersSection,messageSection,visionSection} = useContext(Context);
  return (
    <>
    <nav>
      <div className='container'>
        <img src={navLine}  className="navLine" alt=""  onClick={()=>scrollToSection(homeSection)}/>
        <div id="mobile" onClick={handleClick}>
          <i id="bar" className={isClicked?"fas fa-times":"fas fa-bars"}></i>
        </div>
        <img src={logo0} className="inactiveLogo logo" alt="" />
        <ul
         className={isClicked?"activeLinks":"inactiveLinks"}
        >
          <li onClick={()=>scrollToSection(visionSection)}>رؤيتنا</li>
          <li onClick={()=>scrollToSection(messageSection)}>رسالتنا</li>
          <li onClick={()=>scrollToSection(homeSection)} className="activeLogo logo"><img src={logo0} alt="" /></li>
          <li onClick={()=>scrollToSection(servicesSection)}>خدماتنا</li>
          <li onClick={()=>scrollToSection(partnersSection)}>شركاؤنا</li>
        </ul>
        <img src={navLine} className="navLine" alt="" />
      </div>
    </nav>
    </>
  )
};

export default Navbar;